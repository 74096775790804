<template>
  <v-list class="pl-4 pr-3 discord-dark-3" nav dense>
    <v-list-item
      v-for="route in routes"
      :key="route.name"
      :to="route.path"
      color="grey darken-2"
    >
      <v-list-item-content>
        <v-list-item-title
          :class="`${
            $route.name === route.name ? 'white--text' : 'grey--text'
          } text-subtitle-1`"
        >
          <v-icon small color="grey" class="mr-1">mdi-pound</v-icon>
          {{ route.name.toLowerCase() }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import router from "@/router";
export default {
  name: "Navigation",

  data: () => ({}),
  computed: {
    routes() {
      return router.options.routes.filter((r) => r.name);
    },
    currentRoute() {
      return this.$route;
    },
  },
};
</script>
