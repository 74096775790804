<template>
  <div>
    <v-navigation-drawer
      v-model="nav"
      touchless
      stateless
      absolute
      mini-variant
      mini-variant-width="72"
      class="discord-dark-5 hide-scrollbar text-center"
    >
      <v-avatar class="mx-auto mt-3" size="48">
        <v-tooltip right transition="slide-x-transition" color="primary">
          <template v-slot:activator="{ on, attrs }">
            <img
              alt="Avatar"
              :src="require('../assets/clippy.png')"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>Hi, I'm Clippy!</span>
        </v-tooltip>
      </v-avatar>

      <v-divider class="mx-5 my-3"></v-divider>

      <div v-if="actionableGuilds.length === 0" class="px-3">
        <v-skeleton-loader
          v-for="i in 10"
          :key="i"
          type="avatar"
          class="mb-3"
        ></v-skeleton-loader>
      </div>

      <div v-for="guild in actionableGuilds" :key="guild.id">
        <v-hover v-slot="{ hover }">
          <div>
            <v-avatar
              class="transition-3s"
              :size="activeGuild.id === guild.id ? 42 : 20"
              rounded
              color="white"
              :style="
                activeGuild.id === guild.id
                  ? 'position: absolute; left: -38px; margin-top: 3px'
                  : hover
                  ? 'position: absolute; left: -16px; margin-top: 14px'
                  : 'position: absolute; left: -30px; margin-top: 14px'
              "
            ></v-avatar>
            <v-tooltip right transition="slide-x-transition" color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  :class="`clickable mx-auto mb-3 transition-1s ${
                    guild.icon ? '' : 'discord-dark-2'
                  }`"
                  size="48"
                  :rounded="
                    activeGuild.id === guild.id || hover ? 'lg' : 'circle'
                  "
                  :color="hover && !guild.icon ? 'indigo' : ''"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialog = true"
                >
                  <img
                    v-if="guild.icon"
                    :src="getGuildIconURL(guild)"
                    alt="Avatar"
                  />
                  <span v-else class="text-body-1">{{
                    getInitials(guild.name)
                  }}</span>
                </v-avatar>
              </template>
              <span>{{ guild.name }}</span>
            </v-tooltip>
          </div>
        </v-hover>
      </div>
    </v-navigation-drawer>

    <v-card
      class="d-flex discord-dark-3"
      height="48"
      width="100%"
      elevation="1"
    >
      <v-card
        class="
          pl-6
          text-subtitle-1
          font-weight-medium
          align-self-center
          discord-dark-3
        "
        flat
        >{{ activeGuild.name }}</v-card
      >
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          <span>This feature is coming soon!</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          This feature is a work in progress. Check back soon!
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.transition-3s {
  transition-duration: 0.3s;
}
.transition-2s {
  transition-duration: 0.2s;
}
.transition-1s {
  transition-duration: 0.1s;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Navigation",
  props: ["mainNav"],
  data: () => ({
    nav: true,
    dialog: false,
  }),
  computed: {
    ...mapState(["isAuthenticated", "activeGuild"]),
    ...mapState({
      user: (s) => s.user.user,
      guilds: (s) => s.user.guilds,
    }),
    ...mapGetters("user", {
      actionableGuilds: "actionableGuilds",
      // ownedGuilds: "ownedGuilds",
      // adminGuilds: "adminGuilds",
    }),
    loginURL() {
      return process.env.VUE_APP_API_BASEURL + "/login";
    },
  },
  watch: {
    mainNav: function (val) {
      if (val) this.nav = true;
    },
  },
  methods: {
    ...mapActions("user", {
      getGuilds: "getGuilds",
    }),
    getGuildIconURL(guild) {
      return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png?size=256`;
    },
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.getGuilds()
        .then(() => {})
        .catch(() => {
          window.location.href = this.loginURL;
        });
    }
  },
};
</script>
