<template>
  <v-container fluid class="scrollable" v-cloak>
    <div>
      <v-avatar color="grey darken-2">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #about!</h2>
      <p class="text-caption grey--text">
        This is the start of the #about channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>

    <!-- <div class="fancy">
      <span class="grey--text text--darken-1 caption">{{ date }}</span>
    </div> -->

    <messages keyName="about" :items="messages" />
  </v-container>
</template>

<script>
import Messages from "../components/shared/Messages.vue";
export default {
  name: "About",
  components: {
    Messages,
  },
  data: () => ({
    messages: [
      {
        avatar:
          "https://cdn.discordapp.com/avatars/136264313848594433/a_208482ad2e86f8ffe7820e439caf88d4.gif",
        username: "Eccentrikit",
        time: new Date().toLocaleString(),
        text: ["!about"],
      },
      {
        avatar: require("../assets/clippy.png"),
        username: "Clippy",
        nameColor: "primary",
        bot: true,
        time: new Date().toLocaleString(),
        text: [
          "Hello, this is Clippy v3 - Early Access!",
          "Everything is a work in progress",
          "The theme is intended to be a cheap knock off discord theme because I'm too lazy to come up with something actually unique...",
          "That and it was kinda fun to figure out how to mimic it",
        ],
        delay: 500,
      },
      {
        avatar:
          "https://cdn.discordapp.com/avatars/136264313848594433/a_208482ad2e86f8ffe7820e439caf88d4.gif",
        username: "Eccentrikit",
        time: new Date().toLocaleString(),
        text: ["Feel free to click around and provide feedback", "!discord"],
      },
      {
        avatar: require("../assets/clippy.png"),
        username: "Clippy",
        nameColor: "primary",
        bot: true,
        time: new Date().toLocaleString(),
        text: [
          "You can provide feedback in the REAL discord channel:<br/><a class='link' target='blank' href='https://discord.gg/ENFR3RzZQ7'>https://discord.gg/ENFR3RzZQ7</a>",
        ],
        delay: 500,
        serverInvite: true,
      },
    ],
  }),
  computed: {
    date() {
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  methods: {},
  created() {},
};
</script>
