import Vue from "vue";

// initial state
const state = {
  users: [],
  visible: true,
};

// getters
const getters = {
  onlineUsers(state) {
    return state.users.filter((u) => u.status);
  },
  offlineUsers() {
    return state.users.filter((u) => !u.status);
  },
};

// actions
const actions = {
  getUsers({ commit }) {
    return new Promise((resolve) => {
      Vue.axios.get("/users").then((response) => {
        commit("setUsers", response.data);
        resolve();
      });
    });
  },
};

// mutations
const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  toggleVisible(state) {
    state.visible = !state.visible;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
