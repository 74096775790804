var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"discord-dark-5 hide-scrollbar text-center",attrs:{"touchless":"","stateless":"","absolute":"","mini-variant":"","mini-variant-width":"72"},model:{value:(_vm.nav),callback:function ($$v) {_vm.nav=$$v},expression:"nav"}},[_c('v-avatar',{staticClass:"mx-auto mt-3",attrs:{"size":"48"}},[_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"alt":"Avatar","src":require('../assets/clippy.png')}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Hi, I'm Clippy!")])])],1),_c('v-divider',{staticClass:"mx-5 my-3"}),(_vm.actionableGuilds.length === 0)?_c('div',{staticClass:"px-3"},_vm._l((10),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"mb-3",attrs:{"type":"avatar"}})}),1):_vm._e(),_vm._l((_vm.actionableGuilds),function(guild){return _c('div',{key:guild.id},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-avatar',{staticClass:"transition-3s",style:(_vm.activeGuild.id === guild.id
                ? 'position: absolute; left: -38px; margin-top: 3px'
                : hover
                ? 'position: absolute; left: -16px; margin-top: 14px'
                : 'position: absolute; left: -30px; margin-top: 14px'),attrs:{"size":_vm.activeGuild.id === guild.id ? 42 : 20,"rounded":"","color":"white"}}),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({class:("clickable mx-auto mb-3 transition-1s " + (guild.icon ? '' : 'discord-dark-2')),attrs:{"size":"48","rounded":_vm.activeGuild.id === guild.id || hover ? 'lg' : 'circle',"color":hover && !guild.icon ? 'indigo' : ''},on:{"click":function($event){_vm.dialog = true}}},'v-avatar',attrs,false),on),[(guild.icon)?_c('img',{attrs:{"src":_vm.getGuildIconURL(guild),"alt":"Avatar"}}):_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.getInitials(guild.name)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(guild.name))])])],1)]}}],null,true)})],1)})],2),_c('v-card',{staticClass:"d-flex discord-dark-3",attrs:{"height":"48","width":"100%","elevation":"1"}},[_c('v-card',{staticClass:"\n        pl-6\n        text-subtitle-1\n        font-weight-medium\n        align-self-center\n        discord-dark-3\n      ",attrs:{"flat":""}},[_vm._v(_vm._s(_vm.activeGuild.name))])],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("This feature is coming soon!")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._v(" This feature is a work in progress. Check back soon! ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }