<template>
  <v-app id="inspire">
    <login />

    <v-app-bar
      v-if="isActive"
      app
      clipped-right
      height="48"
      class="discord-dark-2"
      elevation="1"
    >
      <v-app-bar-nav-icon @click="nav = !nav" class="hidden-lg-and-up">
      </v-app-bar-nav-icon>
      <v-toolbar-title class="pl-0 hidden-xs-only">
        <v-icon class="mr-1 grey--text">mdi-pound</v-icon>
        {{ this.$route.name.toLowerCase() }}
      </v-toolbar-title>
      <v-divider vertical class="mx-4 hidden-xs-only"></v-divider>
      <v-responsive class="hidden-xs-only">
        <span class="text-subtitle-1">
          {{ this.$route.meta.desc || "Clippy v3 - Early Access" }}
        </span>
      </v-responsive>

      <v-spacer></v-spacer>

      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="toggleMemberList"
            v-on="on"
            v-bind="attrs"
            icon
            plain
            color="white"
          >
            <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
        </template>
        <span>Member List</span>
      </v-tooltip>

      <v-responsive max-width="220">
        <v-text-field
          v-model="mainSearch"
          :disabled="!searchEnabled"
          background-color="discord-dark-5"
          dense
          flat
          hide-details
          class="rounded-lg"
          clearable
          solo
          placeholder="Search"
          :append-icon="mainSearch ? '' : 'mdi-magnify'"
          @click:append="focusSearch"
          ref="search"
        ></v-text-field>
      </v-responsive>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isActive"
      v-model="nav"
      :touchless="$vuetify.breakpoint.lgAndUp"
      app
      width="310"
      floating
      class="discord-dark-3 pl-16"
    >
      <guilds :mainNav="nav" />

      <navigation />

      <template v-slot:append>
        <v-card class="discord-dark-4">
          <v-card-actions class="py-0">
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  alt="Avatar"
                  v-if="user && user.avatar"
                  :src="user.avatarURL"
                ></v-img>
                <span v-else-if="user && user.username" class="text-h5"
                  >{{ getInitials(user.username) }}
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle v-if="user">{{
                  user.username
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-row class="shrink">
                <settings />
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </template>
    </v-navigation-drawer>

    <users v-if="isActive" />

    <v-main v-if="isActive" class="discord-dark-2">
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
.discord-dark-5 {
  background-color: #202225 !important;
}
.discord-dark-4 {
  background-color: #292b2f !important;
}
.discord-dark-3 {
  background-color: #2f3136 !important;
}
.discord-dark-2 {
  background-color: #36393f !important;
}
.discord-dark-1 {
  background-color: #40444b !important;
}
html {
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}
/* html {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
.scrollable {
  position: absolute;
  overflow-y: auto;
  height: calc(100vh - 72px - 48px);
}
.scrollable::-webkit-scrollbar {
  display: initial;
}
.fill-height {
  height: 100vh;
}

*:not(input) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  --scrollbarBG: #2e3338;
  scrollbar-color: #202225 #2f3136;
  --thumbBG: #202225;
}
*::-webkit-scrollbar {
  width: 24px;
}
/* * {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
} */
*::-webkit-scrollbar-track {
  border-radius: 16px;
  /* background: var(--scrollbarBG); */
  box-shadow: inset 0 0 16px 16px var(--scrollbarBG);
  border: solid 6px transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 16px;
  /* background-color: var(--thumbBG); */
  box-shadow: inset 0 0 16px 16px var(--thumbBG);
  border: solid 6px transparent;
  /* border: 2px solid var(--scrollbarBG); */
}
.v-divider.chat-divider {
  border-color: #42454a !important;
}
.v-divider.v-divider--vertical {
  min-height: 70%;
  max-height: 70%;
  margin: auto;
  border-color: #42454a !important;
}
.selectable * {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.fancy {
  width: 100%;
  line-height: 0.5;
  text-align: center;
}
.fancy span {
  display: inline-block;
  position: relative;
  width: 100%;
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 10px;
  border-bottom: thin solid #42454a;
  top: 0;
  width: calc(50% - 50px);
}
.fancy span:before {
  right: 50%;
  margin-right: 50px;
}
.fancy span:after {
  left: 50%;
  margin-left: 50px;
}
.white-hint .v-messages__message {
  color: white !important;
}
.clickable {
  cursor: pointer;
}
</style>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { debounce } from "./util/utils.js";

import Login from "./components/Login.vue";
import Navigation from "./components/Navigation.vue";
import Guilds from "./components/Guilds.vue";
import Users from "./components/Users.vue";
import Settings from "./components/Settings.vue";

// import utils from "../util/utils"

export default {
  name: "App",
  components: {
    Login,
    Navigation,
    Guilds,
    Users,
    Settings,
  },
  data: () => ({
    nav: null,
    mainSearch: "",
  }),
  computed: {
    ...mapState(["isAuthenticated", "searchEnabled", "searchText"]),
    ...mapState({
      user: (s) => s.user.user,
    }),
    ...mapGetters(["isActive"]),
    loginURL() {
      return process.env.VUE_APP_API_BASEURL + "/login";
    },
  },
  watch: {
    isAuthenticated(val) {
      if (val) {
        this.getUser();
      }
    },
    searchText(val) {
      this.mainSearch = val;
    },
    mainSearch: debounce(function (newVal) {
      this.$store.commit("setSearchText", newVal);
    }, 300),
  },
  methods: {
    ...mapActions("user", {
      getUser: "getUser",
    }),
    ...mapMutations("users", {
      toggleMemberList: "toggleVisible",
    }),
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    focusSearch() {
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },
  },
  created() {},
};
</script>