<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    :transition="transition"
  >
    <v-card>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-card max-width="300" class="mb-16" flat>
            <div class="text-center" justify="center">
              <v-slide-y-reverse-transition>
                <v-chip v-if="start" class="offset"> {{ clippyText }} </v-chip>
              </v-slide-y-reverse-transition>
              <v-img
                class="ml-16"
                max-width="200"
                :src="require('../assets/clippy.png')"
              >
              </v-img>
            </div>
            <v-slide-y-transition>
              <v-btn
                v-if="showLogin"
                color="indigo"
                class="mt-8 ml-8"
                :href="loginURL"
              >
                Login with Discord
              </v-btn>
            </v-slide-y-transition>
          </v-card>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.offset {
  position: absolute;
  right: 170px;
  top: -20px;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Login",

  data: () => ({
    dialog: true,
    transition: false,
    start: false,
    clippyText: "Hi, I'm Clippy",
    accessDenied: true,
  }),
  computed: {
    ...mapState(["isAuthenticated"]),
    showLogin() {
      return !this.isAuthenticated && this.start;
    },
    loginURL() {
      return process.env.VUE_APP_API_BASEURL + "/login";
    },
  },
  methods: {
    ...mapMutations(["setAuthenticated"]),
    ...mapActions("user", {
      poke: "poke",
    }),
  },
  created() {
    setTimeout(() => {
      this.poke()
        .then((response) => {
          if (response.status === 200) {
            this.clippyText = "Welcome back!";

            this.setAuthenticated();
            this.accessDenied = false;
            setTimeout(() => {
              this.dialog = false;
            }, 1000);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.clippyText = "Oops, you don't seem to be authorized...";
          }
        })
        .then(() => {
          this.start = true;
        });
    }, 200);
  },
};
</script>
