<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    clipped
    right
    width="240"
    floating
    class="discord-dark-3"
    :touchless="$vuetify.breakpoint.lgAndUp"
  >
    <v-subheader
      style="height: 24px"
      class="mt-4 text-caption font-weight-medium"
      >BOT &#8212; 1
    </v-subheader>
    <v-list two-line subheader dense class="pb-0">
      <v-list-item>
        <v-list-item-icon class="mr-3">
          <v-badge
            avatar
            bottom
            bordered
            offset-x="14"
            offset-y="0"
            color="green"
            class="small"
          >
            <v-avatar size="36">
              <img alt="Avatar" :src="require('../assets/clippy.png')" />
            </v-avatar>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content class="pt-0">
          <v-list-item-title>
            <span class="primary--text text-subtitle-1 font-weight-bold">
              Clippy
            </span>
            <v-chip
              class="pa-1 font-weight-light"
              label
              color="#5865F2"
              x-small
            >
              BOT
            </v-chip>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="playing"
            class="text-caption text-truncate grey--text"
          >
            Playing {{ playing.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-skeleton-loader
      v-if="users.length === 0"
      class="mt-2"
      type="heading,list-item-avatar,list-item-avatar"
    ></v-skeleton-loader>

    <v-subheader
      v-if="onlineUsers.length"
      class="text-caption font-weight-medium"
    >
      USERS &#8212; {{ onlineUsers.length }}
    </v-subheader>
    <v-list subheader dense class="pb-0">
      <v-list-item v-for="user in onlineUsers" :key="user.id" class="mb-2">
        <v-list-item-icon class="my-0 mr-3">
          <v-badge
            avatar
            bottom
            bordered
            offset-x="14"
            offset-y="0"
            class="small"
            :color="`${
              getTimeDifference(convertTime(user.lastClipTime)) > 5 * 60 * 1000
                ? getTimeDifference(convertTime(user.lastActiveTime)) >
                  5 * 60 * 1000
                  ? 'red darken-3'
                  : 'orange darken-3'
                : 'green'
            }`"
          >
            <v-avatar color="grey" size="36">
              <v-img
                v-if="
                  currentUser &&
                  currentUser.avatar &&
                  user.id === currentUser.id
                "
                :src="avatarURL"
              ></v-img>
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="grey--text text-subtitle-1 font-weight-bold"
          >
            {{ user.username }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-subheader
      v-if="offlineUsers.length"
      class="text-caption font-weight-medium"
    >
      OFFLINE &#8212; {{ offlineUsers.length }}
    </v-subheader>
    <v-list subheader dense class="pb-0">
      <v-list-item v-for="user in offlineUsers" :key="user.id" class="mb-2">
        <v-list-item-icon class="my-0 mr-3">
          <v-badge
            avatar
            bottom
            bordered
            offset-x="14"
            offset-y="0"
            class="small"
            color="grey darken-1"
          >
            <v-avatar color="grey" size="36">
              <v-img
                v-if="
                  currentUser &&
                  currentUser.avatar &&
                  user.id === currentUser.id
                "
                :src="avatarURL"
              ></v-img>
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="grey--text text-subtitle-1 font-weight-bold"
            >{{ user.username }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<style>
.v-badge.small .v-badge__badge {
  border-radius: 8px;
  height: 16px;
  width: 16px;
  min-width: 0;
  padding: 0;
}
.v-badge.small.v-badge--bordered .v-badge__badge::after {
  border-width: 3px !important;
  border-color: #2f3136 !important;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Navigation",

  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapState(["isAuthenticated"]),
    ...mapState("clips", ["playing"]),
    ...mapState("users", ["users", "visible"]),
    ...mapState({
      currentUser: (s) => s.user.user,
    }),
    // ...mapState({
    //   users: (s) => s.users.users,
    // }),
    ...mapGetters("user", ["avatarURL"]),
    ...mapGetters("users", {
      onlineUsers: "onlineUsers",
      offlineUsers: "offlineUsers",
    }),
  },
  watch: {
    visible(val) {
      this.drawer = val;
    },
  },
  methods: {
    ...mapActions("users", {
      getUsers: "getUsers",
    }),
    convertTime(time) {
      let t = new Date(time);
      let tz = t.getTimezoneOffset() * 60 * 1000;
      return new Date(t.getTime() - tz);
    },
    getTimeDifference(date) {
      return new Date().getTime() - date.getTime();
    },
    loop() {
      this.getUsers();
      setTimeout(() => {
        this.loop();
      }, 30 * 1000);
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.loop();
    }
  },
};
</script>
