import Vue from "vue";
import VueRouter from "vue-router";
import Welcome from "../views/Welcome.vue";
import Clips from "../views/Clips.vue";
// import Favorites from "../views/Favorites.vue";
import About from "../views/About.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/clips",
    name: "Clips",
    meta: {
      desc: "All Clips",
    },
    component: Clips,
  },
  {
    path: "/favorites",
    name: "Favorites",
    meta: {
      desc: "Favorite Clips",
    },
    component: Clips,
  },
  {
    path: "/about",
    name: "About",
    component: About,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.name} - Clippy`;
  next();
});

export default router;
