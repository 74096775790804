import Vue from "vue";

// initial state
const state = {
  search: "",
  clips: [],
  playing: null,
  playingTimer: null,
};

// getters
const getters = {
  filteredClips: (state, getters, rootState) => {
    return state.clips.filter((clip) => {
      return (
        clip.name.toLowerCase().includes(rootState.searchText.toLowerCase()) ||
        clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase()) ||
        clip.voices.toLowerCase().includes(rootState.searchText.toLowerCase())
      );
    });
  },
  filteredFavoriteClips: (state, getters, rootState) => {
    return state.clips.filter((clip) => {
      return (
        clip.favorite &&
        (clip.name.toLowerCase().includes(rootState.searchText.toLowerCase()) ||
          clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase()) ||
          clip.voices.toLowerCase().includes(rootState.searchText.toLowerCase()))
      );
    });
  },
  getClip: (state) => (clip) => {
    if (clip) return state.clips.find((x) => x.clip === clip);
    else return {};
  },
};

// actions
const actions = {
  getClips({ commit, rootState }) {
    commit("setClips", []);
    return new Promise((resolve) => {
      Vue.axios.get(`/clips/${rootState.activeGuild.id}`).then((response) => {
        commit("setClips", response.data);
        resolve(response);
      });
    });
  },
  playClip({ commit, state }, clip) {
    return new Promise((resolve, reject) => {
      clip.playing = true;
      clearTimeout(state.playingTimer);
      commit("setClipPlaying", clip);
      Vue.axios
        .get(`/play/${clip.clip}`)
        .then((response) => {
          state.playingTimer = setTimeout(() => {
            commit("setClipNotPlaying");
          }, 3000);
          resolve(response);
        })
        .catch((err) => {
          commit("setClipNotPlaying");
          clip.error = true;
          setTimeout(() => {
            clip.error = false;
          }, 2000);
          reject(err);
        })
        .then(() => {
          clip.playing = false;
        });
    });
  },
  favoriteClip({ commit }, clip) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`/favorite/${clip.clip}`)
        .then((response) => {
          let favorite = response.data.favorite;
          commit("setClipFavorite", { clip, favorite });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  setClips(state, clips) {
    clips.forEach((c) => {
      c.playing = false;
      c.error = false;
    });
    state.clips = clips.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
    });
  },
  setClipPlaying(state, clip) {
    state.playing = clip;
  },
  setClipNotPlaying(state) {
    state.playing = null;
  },
  setClipFavorite(state, { clip, favorite }) {
    clip.favorite = favorite;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
