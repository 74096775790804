<template>
  <v-container fluid class="scrollable">
    <div>
      <v-avatar color="grey darken-2">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #{{ $route.name.toLowerCase() }}!</h2>
      <p class="text-caption grey--text">
        This is the start of the #{{ $route.name.toLowerCase() }} channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>

    <messages keyName="clips" :existing="messages" />

    <!-- <v-row v-if="clips.length === 0">
      <v-col v-for="i in 24" :key="i" xl="3" lg="4" sm="6" cols="12">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col
        v-for="clip in clips"
        :key="clip.clip"
        xl="3"
        lg="4"
        md="4"
        sm="6"
        cols="12"
        class="d-flex flex-column"
      >
        <v-card
          class="my-0 flex d-flex flex-column"
          @click="play(clip)"
          :ripple="false"
          :disabled="clip.playing"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title
              class="text-h6 py-1 wrap-text"
              v-text="clip.name"
            ></v-card-title>

            <v-btn
              class="ma-2"
              icon
              plain
              @click.stop="favoriteClip(clip)"
              :color="clip.favorite ? 'red' : ''"
            >
              <v-icon>mdi-heart</v-icon>
            </v-btn>
            <!-- <v-avatar class="ma-3" size="125" tile>
              <v-img :src="item.src"></v-img>
            </v-avatar> -->
          </div>

          <div class="flex d-flex flex-column">
            <v-divider class="mx-4"></v-divider>

            <v-card-text
              class="
                pt-0
                pb-2
                grey--text
                text--lighten-1
                flex
                d-flex
                flex-column
                justify-space-between
              "
            >
              <div class="py-2 text-subtitle-1">
                <v-icon color="grey" dense>mdi-account-voice</v-icon>
                {{ clip.voices }}
              </div>

              <div>
                {{ clip.desc }}
              </div>
            </v-card-text>

            <v-overlay absolute v-if="clip.playing">
              <lottie-animation
                style="height: 150px"
                :animationData="require('../assets/speakers.json')"
                :loop="true"
              />
            </v-overlay>
            <v-overlay absolute v-if="clip.error">
              <lottie-animation
                style="height: 150px"
                :animationData="require('../assets/error.json')"
                :loop="true"
              />
            </v-overlay>
          </div>
          <!-- <v-divider class="mx-4"></v-divider> -->

          <!-- <v-card-text class="pt-0 pb-2">
            <div class="py-2 text-subtitle-1">
              <v-icon dense>mdi-account-voice</v-icon> {{ clip.voices }}
            </div>

            <div>
              {{ clip.desc }}
            </div>
          </v-card-text>
          <v-overlay absolute :value="clip.playing">
            <lottie-animation
              style="height: 150px"
              :animationData="require('../assets/speakers.json')"
              :loop="true"
            />
          </v-overlay> -->
        </v-card>
      </v-col>
    </v-row>

    <v-footer app height="72" inset class="discord-dark-2">
      <v-text-field
        disabled
        background-color="discord-dark-1"
        dense
        flat
        class="rounded-lg white-hint dark-placeholder"
        solo
        prepend-inner-icon="mdi-plus-circle"
        placeholder="You do not have permissions to upload in this channel"
        hint="●●● Clippy is typing..."
        :persistent-hint="hint"
      ></v-text-field>
    </v-footer>
  </v-container>
</template>

<style scoped>
.wrap-text {
  word-break: keep-all;
}
</style>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import Messages from "../components/shared/Messages.vue";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "Clips",
  components: {
    Messages,
    LottieAnimation,
  },
  data: () => ({
    messages: [],
    hint: true,
  }),
  computed: {
    ...mapState(["searchText"]),
    ...mapGetters(["isActive"]),
    ...mapState("user", ["user"]),
    ...mapGetters("clips", {
      filteredClips: "filteredClips",
      filteredFavoriteClips: "filteredFavoriteClips",
    }),
    favorite() {
      return this.$route.name == "Favorites";
    },
    clips() {
      if (this.favorite) {
        return this.filteredFavoriteClips;
      }
      return this.filteredClips;
    },
    date() {
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  watch: {
    $route() {
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${this.searchText}`,
      ]);
      Vue.set(this.messages[1], "text", [
        `I've found ${this.clips.length} clips for you!`,
      ]);
    },
    searchText(val) {
      Vue.set(this.messages[0], "edited", true);
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${val}`,
      ]);
      Vue.set(this.messages[1], "edited", true);
      Vue.set(this.messages[1], "text", [
        `I've found ${this.clips.length} clips for you!`,
      ]);
    },
  },
  methods: {
    ...mapMutations(["enableSearch", "disableSearch"]),
    ...mapActions("clips", {
      getClips: "getClips",
      playClip: "playClip",
      favoriteClip: "favoriteClip",
    }),
    // favoriteClip(clip) {
    //   Vue.set(clip, "favorite", !clip.favorite);
    // },
    play(clip) {
      if (clip.playing) {
        return;
      }
      this.playClip(clip);
    },
    init() {
      this.messages.push({
        avatar: this.user.avatarURL,
        username: this.user.username,
        time: new Date().toLocaleString(),
        text: [`!${this.$route.name.toLowerCase()}`],
      });
      this.getClips().then(() => {
        this.messages.push({
          avatar: require("../assets/clippy.png"),
          username: "Clippy",
          nameColor: "primary",
          bot: true,
          time: new Date().toLocaleString(),
          text: [`I've found ${this.clips.length} clips for you!`],
        });
        this.hint = false;
        this.enableSearch();
      });
    },
  },
  created() {
    this.init();
  },
  destroyed() {
    this.disableSearch();
  },
};
</script>
