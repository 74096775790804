<template>
  <div>
    <v-list two-line color="transparent" class="selectable">
      <template v-for="(item, index) in messages">
        <v-list-item class="pl-0" :key="index">
          <v-list-item-avatar class="align-self-start mt-2">
            <!-- <v-skeleton-loader type="avatar"></v-skeleton-loader> -->
            <v-img :src="item.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="py-0">
            <v-list-item-title>
              <span
                :class="`${
                  item.nameColor || 'grey'
                }--text text-subtitle-1 font-weight-bold`"
              >
                {{ item.username }}
              </span>
              <v-chip
                v-if="item.bot"
                class="pa-1 mr-1 font-weight-light"
                label
                color="#5865F2"
                x-small
              >
                BOT
              </v-chip>
              <span class="text-caption ml-1 grey--text text--darken-1">
                {{ item.time }}
              </span>
            </v-list-item-title>
            <p
              v-for="(text, index2) in item.text"
              :key="index2"
              class="text-body-2 mb-1"
            >
              <span v-html="text"> </span>
              <span
                v-if="item.edited"
                class="grey--text text--darken-1 text-caption"
              >
                (edited)
              </span>
            </p>
          </v-list-item-content>
        </v-list-item>
        <server-invite :key="`${index}-si`" v-if="item.serverInvite" />
      </template>
    </v-list>
    <v-footer app height="72" inset class="discord-dark-2">
      <v-text-field
        disabled
        background-color="discord-dark-1"
        dense
        flat
        class="rounded-lg white-hint dark-placeholder"
        solo
        placeholder="You do not have permissions to send messages in this channel"
        :hint="hintText"
        :persistent-hint="hint"
      ></v-text-field>
    </v-footer>
  </div>
</template>

<style>
.dark-placeholder ::placeholder {
  color: #72767d !important;
}
</style>

<script>
import ServerInvite from "./ServerInvite.vue";
export default {
  name: "Messages",
  props: ["keyName", "existing", "items", "forceReplay"],
  components: {
    ServerInvite,
  },
  data: () => ({
    messages: [],
    hint: false,
    hintText: "●●● Eccentrikit is typing...",
  }),
  computed: {
    date() {
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  methods: {
    playNotification() {
      var audio = new Audio(require("../../assets/discord-notification.mp3"));
      audio.volume = 0.1;
      audio.play();
    },
  },
  created() {
    if (this.existing) {
      this.messages = this.existing;
    }
    if (this.items) {
      let welcomeExperienced =
        localStorage.getItem(`welcome-experienced-${this.keyName}`) || "";
      if (welcomeExperienced && !this.forceReplay) {
        this.messages = this.items;
        return;
      }
      localStorage.setItem(`welcome-experienced-${this.keyName}`, true);
      let _this = this;
      _this.hint = true;
      _this.hintText = `●●● ${_this.items[0].username} is typing...`;
      let count = _this.items.length;
      let runningDelay = 0;
      for (let i = 0; i < count; i++) {
        setTimeout(() => {
          let m = _this.items[i];
          m.time = new Date().toLocaleString();
          _this.messages.push(m);
          if (i === count - 1) {
            _this.hint = false;
          } else {
            _this.hintText = `●●● ${_this.items[i].username} is typing...`;
          }
          // _this.playNotification();
        }, (runningDelay += _this.items[i].delay || 1500));
      }
    }
  },
};
</script>
