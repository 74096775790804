import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import users from "./modules/users";
import clips from "./modules/clips";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    searchEnabled: false,
    searchText: "",
    activeGuild: {
      id: "136264752233054208",
      name: "Team Halp",
    },
  },
  getters: {
    isActive: () => {
      return !!user.state.user;
    },
  },
  mutations: {
    setAuthenticated(state) {
      state.isAuthenticated = true;
    },
    enableSearch(state) {
      state.searchEnabled = true;
    },
    disableSearch(state) {
      state.searchEnabled = false;
      state.searchText = "";
    },
    setSearchText(state, searchText) {
      state.searchText = searchText || "";
    },
    clearSearchText(state) {
      state.searchText = "";
    },
  },
  actions: {},
  modules: {
    user,
    users,
    clips,
  },
});
