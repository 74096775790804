<template>
  <clips />
</template>

<script>
import Clips from "../components/Clips";

export default {
  name: "ClipsView",

  components: {
    Clips,
  },
};
</script>
